import {IProps} from './types';

import classNames from 'classnames';
import React, {
    FC,
    useEffect,
    useRef,
    useState,
} from 'react';
import {Provider} from 'react-redux';
import store from 'store/store';

import {useIntl} from 'helpers/hooks';

import {
    hasRoutePermission,
    isNeedToWatchClient,
} from 'instances/auth/functions';
import {useAuth} from 'instances/auth/hooks';
import {useClientByUser} from 'instances/clients/hooks';

import {
    ROUTE,
} from 'components/constants';
import {
    requestAnimFrame,
} from 'components/Header/functions';

import Basket from './Basket';
import LinkBar from './LinkBar';
import Locale from './Locale';
import LogoAndToggle from './LogoAndToggle';
import NameAndUserDropdown from './NameAndUserDropdown';
import Notifications from './Notifications/NotificationsIcon';
import NotificationsSliderWrapper from './Notifications/NotificationsSlider';

import {INTL_DATA} from './intl';

import styles from './Header.module.scss';

const Header: FC<IProps> = (props) => {
    const [isSticky, setIsSticky] = useState(false);

    const intl = useIntl();

    const {
        client,
        getClient,
    } = useClientByUser();
    const {
        auth,
        postLogoutAuth,
    } = useAuth();

    const bottomPartRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        (async () => {
            if (!auth) {
                return;
            }
            if (!isNeedToWatchClient(auth)) {
                return;
            }
            if (client) {
                return;
            }

            await getClient();
        })();
    }, [auth]);
    useEffect(() => {
        window.addEventListener('wheel', onScroll);

        return () => {
            window.removeEventListener('wheel', onScroll);
        };
    }, []);

    const onScroll = () => {
        requestAnimFrame(() => {
            if (bottomPartRef.current) {
                const rectPosition = bottomPartRef.current.getBoundingClientRect();
                const sticky = (rectPosition.top <= 1);

                setIsSticky(sticky);
            }
        });
    };

    return (
        <header role={'banner'}>
            <nav className={classNames('navbar navbar-dark navbar-expand-lg bg-dark supra', styles.topNavBar)}>
                <div className={'container-fluid'}>
                    <h1 className={classNames('navbar-nav me-auto fs-5', styles.headerLabel)}>
                        {intl(INTL_DATA.HEADER_TOP)}
                    </h1>
                    <Locale
                        locale={props.locale}
                        availableLocales={props.availableLocales}
                        changeLocale={props.changeLocale}
                    />
                </div>
            </nav>
            <div ref={bottomPartRef}>
                <div className={classNames({
                    [styles.middleNavBarSticky]: isSticky,
                    [styles.middleNavBarOrigin]: !isSticky,
                })}
                >
                    <nav className={'navbar navbar-dark navbar-expand-lg bg-dark supra'} aria-label={'Global navigation'}>
                        <div className={'container-fluid d-flex flex-row justify-content-between'}>
                            <LogoAndToggle
                                auth={auth}
                                isSticky={isSticky}
                            />
                            <LinkBar
                                auth={auth}
                                isSticky={isSticky}
                            />
                            <div className={'d-flex flex-row align-self-center'}>
                                <NameAndUserDropdown
                                    auth={auth}
                                    clientCompanyName={client?.name || ''}
                                    postLogoutAuth={postLogoutAuth}
                                />
                                {
                                    auth &&
                                    hasRoutePermission(auth, ROUTE.BASKET) &&
                                    <Basket
                                        auth={auth}
                                    />
                                }
                                <Provider store={store}>
                                    {
                                        auth &&
                                    hasRoutePermission(auth, ROUTE.NOTIFICATIONS) &&
                                        <Notifications
                                            auth={auth}
                                        />
                                    }
                                </Provider>
                            </div>
                        </div>
                    </nav>
                </div>
                {auth && auth?.notificationsStatus?.isMWEnabled &&
                <Provider store={store}>
                    <NotificationsSliderWrapper/>
                </Provider>}
            </div>
        </header>
    );
};

export default Header;
