import {IProps} from './types';

import React, {
    FC,
    useLayoutEffect,
    useState,
} from 'react';
import {useSelector} from 'react-redux';
import {
    Link,
    useLocation,
} from 'react-router-dom';
import {
    RootState,
    useAppDispatch,
} from 'store/store';

import {hasRoutePermission} from 'instances/auth/functions';

import {ROUTE} from 'components/constants';
import {getNewNotifications} from 'components/Notifications/slice/notificationsSlice';

import {
    notificationsIconOrange,
    notificationsIconWhite,
} from './media';

const NotificationsIcon: FC<IProps> = (props) => {
    const [isHover, setIsHover] = useState(false);
    const location = useLocation();

    const {notificationsCounter, notificationToSubmit} = useSelector((state: RootState) => state.notifications);
    const appDispatch = useAppDispatch();
    const {auth} = props;

    useLayoutEffect(() => {
        if (!auth?.notificationsStatus?.isMWEnabled || !hasRoutePermission(auth, ROUTE.NOTIFICATIONS)) {
            return;
        } else {
            appDispatch(getNewNotifications());
        }
    }, [notificationsCounter, notificationToSubmit, auth?.notificationsStatus?.isMWEnabled]);

    return (
        <div>
            <Link to={'/notifications'} className={'d-flex flex-row'}>
                <>
                    <img
                        src={location.pathname === ROUTE.NOTIFICATIONS || isHover ? notificationsIconOrange : notificationsIconWhite}
                        onMouseEnter={() => setIsHover(true)}
                        onMouseLeave={() => setIsHover(false)}
                    />
                    {auth?.notificationsStatus?.isMWEnabled && notificationsCounter !== 0 &&
                            <div className={`d-flex align-self-start badge bg-info rounded-pill fs-7 translate-middle ${isHover ? 'text-primary' : 'text-white'}`}>
                                {notificationsCounter}
                            </div>}
                </>
            </Link>
        </div>
    );
};

export default NotificationsIcon;
