import {
    FC,
    useEffect,
    useState,
} from 'react';

import {myThrottle} from 'helpers/functions/throttle';

import {SESSION_KEEPER_TIME} from 'instances/auth/constants';
import {useAuth} from 'instances/auth/hooks';

const SessionKeeper: FC = () => {
    const [logoutTimerId, setLogoutTimerId] = useState<ReturnType<typeof setTimeout> | null>(null);
    const [clickedAt, setClickedAt] = useState(0);

    const {
        auth,
        getAuth,
        postLogoutAuth,
    } = useAuth();

    const isLoadedAuth = !!auth;

    useEffect(() => {
        const setFromClickEvent = (e: MouseEvent) => {
            setClickedAt(e.timeStamp);
        };

        window.addEventListener('click', myThrottle(setFromClickEvent, 3000));

        return () => {
            window.removeEventListener('click', setFromClickEvent);
        };
    }, []);
    useEffect(() => {
        (async () => {
            if (!auth) {
                return;
            }

            myThrottle(async () => await getAuth(), 3000)();
            logoutTimerId && clearTimeout(logoutTimerId);

            const newLogoutTimerId = setTimeout(async () => {
                await postLogoutAuth();
            }, SESSION_KEEPER_TIME);

            setLogoutTimerId(newLogoutTimerId);
        })();

        return () => {
            logoutTimerId && clearTimeout(logoutTimerId);
        };
    }, [clickedAt, isLoadedAuth]);

    return null;
};

export default SessionKeeper;
