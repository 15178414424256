import {
    IApplicationVersion,
    IField,
} from 'instances/servicesVersions/types/instance';

import React, {
    FC,
    useLayoutEffect,
    useState,
} from 'react';

import {useAuth} from 'instances/auth/hooks';
import {isNeedToWatchServiceVersion} from 'instances/servicesVersions/functions';
import {useActiveVersionsByUser} from 'instances/servicesVersions/hooks';

import ClaimModal from 'components/Footer/ClaimModal';
import {Button} from 'components/Helpers/Buttons';

import {USER_TYPE} from '../../instances/users/constants';

interface IProps {
    locale: string;
}

const Footer: FC<IProps> = ({
    locale,
}) => {
    const [serviceVersionToOrder, setServiceVersionToOrder] = useState<IApplicationVersion | null>(null);
    const [isPending, setIsPending] = useState(false);

    const {auth} = useAuth();

    const {
        activeServiceVersions,
        getActiveServiceVersions,
        getClaimActiveVersion,
    } = useActiveVersionsByUser();

    const onClick = async () => {
        setIsPending(true);

        if (!activeServiceVersions) {
            return;
        }

        const res = await getClaimActiveVersion();

        if (!res.version) {
            return;
        }
        if (!res.version || res.error) {
            return;
        }

        setServiceVersionToOrder(res.version);
        setIsPending(false);
    };
    const onChangeServiceVersionFields = (fields: IField[]) => {
        if (!serviceVersionToOrder) {
            return;
        }

        setServiceVersionToOrder({
            ...serviceVersionToOrder,
            fields,
        });
    };
    const badLocalTranslate = (locale: string) => {
        if (locale === 'ru') {
            return 'Политика конфиденциальности';
        }

        return 'Privacy policy';
    };

    useLayoutEffect(() => {
        (async () => {
            if (auth) {
                if (!isNeedToWatchServiceVersion(auth)) {
                    return;
                }

                await getActiveServiceVersions();
            }
        })();
    }, [auth]);

    return (
        <div className={'footer sticky-bottom bg-dark navbar-dark mt-auto pt-2'}>
            <div className={'container-fluid mh-100 pt-3 pb-2 mb-3'}>
                <h2 className={'visually-hidden'}>
                Orange Business
                </h2>
                <div className={'container footer-terms d-flex flex-row justify-content-center align-items-center'}>
                    <div className={'col-2'}>
                        <a target={'_blank'} className={'link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover'} href={'https://www.orange-business.com/ru/politika-konfidencialnosti'} rel={'noreferrer'}>
                            {badLocalTranslate(locale)}
                        </a>
                    </div>
                    <div className={'col-2'}/>
                    <div className={'col-4 fw-bold text-center text-white'}>
                        {`© Orange ${new Date().getFullYear()}`}
                    </div>
                    <div className={'col-2'}/>
                    <div className={'col-2 d-flex justify-content-end align-items-end'}>
                        {
                            auth &&
                        [USER_TYPE.SIMPLE_USER, USER_TYPE.CLIENT_ADMIN].includes(auth.type) &&
                        <Button
                            text={isPending ? 'Загрузка' : 'Обратная связь'}
                            onClick={onClick}
                        />
                        }
                    </div>
                </div>
                {
                    auth &&
                [USER_TYPE.SIMPLE_USER, USER_TYPE.CLIENT_ADMIN].includes(auth.type) &&
                <ClaimModal
                    serviceVersion={serviceVersionToOrder}
                    onChangeServiceVersionFields={onChangeServiceVersionFields}
                    onClose={() => setServiceVersionToOrder(null)}
                />
                }
            </div>
        </div>
    );
};

export default Footer;
