import * as NSRedux from './types/redux';
import {
    IGet,
    IPostLogout,
} from './types/responses';
import {IRootReduxState} from 'instances/types';
import {IListenByAuth} from 'instances/users/types/responses';

import {
    useDispatch,
    useSelector,
} from 'react-redux';

import {WS_EVENT_TYPE} from 'tools/constants';

import {
    ACTION_TYPE,
} from './constants';
import * as fetches from './fetches';

export function useAuth() {
    const auth = useSelector<IRootReduxState, NSRedux.IState>((state) => state.auth);
    const dispatch = useDispatch();

    const loginAuth = async (): Promise<void> => {
        await fetches.login();
    };
    const getAuth = async (): Promise<IGet> => {
        const res = await fetches.get();

        if (!res.auth || res.error) {
            return res;
        }

        dispatch<NSRedux.IGet>({
            type: ACTION_TYPE.AUTH_GET,
            auth: res.auth,
        });

        return res;
    };
    const postLogoutAuth = async (): Promise<IPostLogout> => {
        const res = await fetches.postLogout();

        if (res.error) {
            console.log(res.error);

            return res;
        }

        dispatch<NSRedux.IPostLogout>({
            type: ACTION_TYPE.AUTH_POST_LOGOUT,
        });

        return res;
    };
    const updateAuthByListening = (res: IListenByAuth): void => {
        switch (res.type) {
            case WS_EVENT_TYPE.UPDATE: {
                if (!res.user || res.error) {
                    console.log(res.error);

                    return;
                }
                if (!auth) {
                    return;
                }

                dispatch<NSRedux.IGet>({
                    type: ACTION_TYPE.AUTH_GET,
                    auth: {
                        ...auth,
                        ...res.user,
                    },
                });

                return;
            }
        }
    };

    return {
        auth,
        loginAuth,
        getAuth,
        postLogoutAuth,
        updateAuthByListening,
    };
}
