import {IProps} from './types';
import {IListenByUser} from 'instances/basketServices/types/responses';

import React, {
    FC,
    useEffect,
    useState,
} from 'react';

import {WebSocket} from 'tools/components';
import Query from 'tools/Query';

import {isNeedToWatchBasketServices} from 'instances/auth/functions';
import {useAuth} from 'instances/auth/hooks';
import {INSTANCE_PATH} from 'instances/basketServices/constants';
import {getDefaultListenFullQueryUser} from 'instances/basketServices/functions';
import {useBasketServicesActualByUser} from 'instances/basketServices/hooks';

const ListenBasketServicesByUser: FC<IProps> = (props) => {
    const [locale, setLocale] = useState(props.locale);

    const {
        basketServices,
        getDemoBasketServices,
        updateBasketServicesByListening,
    } = useBasketServicesActualByUser();

    const {auth} = useAuth();

    const onMessage = (res: IListenByUser) => {
        updateBasketServicesByListening(res);
    };

    useEffect(() => {
        (async () => {
            if (basketServices) {
                return;
            }
            if (!auth) {
                return;
            }
            if (!isNeedToWatchBasketServices(auth)) {
                return;
            }

            await getDemoBasketServices({locale});
        })();
    }, [auth]);
    useEffect(() => {
        if (props.locale === locale) {
            return;
        }

        setLocale(props.locale);
    }, [props.locale]);
    useEffect(() => {
        if (!auth) {
            return;
        }
        if (!isNeedToWatchBasketServices(auth)) {
            return;
        }

        (async () => {
            await getDemoBasketServices({locale});
        })();
    }, [locale]);

    if (!basketServices) {
        return null;
    }
    if (!auth) {
        return null;
    }
    if (!isNeedToWatchBasketServices(auth)) {
        return null;
    }

    return (
        <WebSocket
            path={`${INSTANCE_PATH}/listen${Query.stringify(getDefaultListenFullQueryUser())}`}
            onMessage={onMessage}
        />
    );
};

export default ListenBasketServicesByUser;
