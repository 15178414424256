export const INSTANCE_PATH = '/incidents';
export const LS_INCIDENTS_FILTER = 'LS_INCIDENTS_FILTER';

export enum STATUS {
    CLOSED = 'CLOSED',
    RESTORED = 'RESTORED',
    NEW = 'NEW',
    HOLD = 'HOLD',
    WORK = 'WORK',
}

export enum PRIORITY {
    P1 = '01',
    P2 = '02',
    P3 = '03',
    P4 = '04',
}

export enum CATEGORY {
    INC = 'INC',
    RFC = 'RFC',
}

export enum SORT_BY {
    INCIDENT = 'INCIDENT',
    CATEGORY = 'CATEGORY',
    STATUS = 'STATUS',
    CONTRACT = 'CONTRACT',
    SHORT_DESCRIPTION = 'SHORT_DESCRIPTION',
    ADDRESS = 'ADDRESS',
    CREATED_AT = 'CREATED_AT',
    CID = 'CID',
    SVID = 'SVID',
    CLIENT_CI = 'CLIENT_CI',
    CLIENT_INCIDENT_NUMBER = 'CLIENT_INCIDENT_NUMBER',
}

export enum NOTIFICATION_ERROR {
    PERMISSION_DENIED = 'PERMISSION_DENIED',
}
