import {
    IOrdersFilters,
    IOrdersSuggestions,
    IUserOrder,
} from './types';
import {
    IGetOrders,
    IGetOrdersSuggestions,
} from './types/responses';

import config from 'config';

import {INSTANCE_PATH} from './constants';

export const getAllOrders = async (query: IOrdersFilters): Promise<IGetOrders> => {
    const queryStr = new URLSearchParams(
        Object.entries(query).flatMap(([key, value]) =>
            Array.isArray(value) ? value.map((v) => [key, v]) : [[key, value]]
        )
    ).toString();

    const url = `${config.URL}${INSTANCE_PATH}/?${queryStr}`;

    const res = await fetch(url, {
        credentials: 'include',
    });

    if (!res.ok) {
        const error = await res.json();

        if (res.status === 401) {
            throw new Error(error);
        }
        if (res.status === 404) {
            throw new Error(error);
        }
        if (res.status === 500) {
            throw new Error(error);
        }
    }

    const data = (await res.json().then((data) => data.orders)) as IUserOrder[];

    return {orders: data};
};

export const getSuggestionsForOrders = async (): Promise<IGetOrdersSuggestions> => {
    const locale = localStorage.getItem('locale') || 'ru';

    const url = `${config.URL}${INSTANCE_PATH}/suggestions?locale=${locale}`;

    const res = await fetch(url, {
        credentials: 'include',
    });

    if (!res.ok) {
        if (res.status === 401) {
            const error = await res.json();

            throw new Error(error);
        }
        if (res.status === 404) {
            const error = await res.json();

            throw new Error(error);
        }
        if (res.status === 500) {
            const error = await res.json();

            throw new Error(error);
        }
    }

    const data = (await res.json().then((data) => data.suggestions)) as IOrdersSuggestions;

    return {suggestions: data};
};
