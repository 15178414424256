import {IEquippedClient} from 'instances/equippedClients/types';
import {
    IDataItem,
    IUserForClient,
} from 'instances/equippedUsers/types';

import classNames from 'classnames';
import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';

import {formatDate} from 'tools/functions';

import {
    IDENTIFIER_TYPE,
    PASSPORT_TYPE,
} from 'instances/equippedUsers/constants';
import {getDefaultDataItem} from 'instances/equippedUsers/functions';

import {
    IOrangeDateRef,
    OrangeDate,
    OrangeInput,
    OrangeSelect,
    useOrangeInputRef,
} from 'components/Helpers/Inputs';
import {MaterialTooltip} from 'components/Helpers/MaterialUI';

import {
    INPUT,
    NAME_SYMBOL_REGEX,
    PASSPORT_AUTHORITY_REGEX,
} from './constants';
import {formatPassportType} from './functions';
import {
    successIcon,
    warningIcon,
} from './media';

import styles from './PdIdentifiersItem.module.scss';

interface IProps {
    equippedClient: IEquippedClient;
    equippedUser: IUserForClient;
    isLast: boolean;

    onDuplicate(): void;
    onDuplicateAll(): void;
    onRemove: ((id: string) => void) | null;
}

export interface IFormItem {
    getUser(): IUserForClient;
    getIsEdit(): boolean;
    getValue(): IDataItem;
    setValue(value: IDataItem): void;
    validate(): Promise<boolean>;
    isPartlyFilled(): boolean;
    isFullFilled(): boolean;
}

const PdIdentifiersItem = forwardRef<IFormItem, IProps>((props, ref) => {
    const [passportType, setPassportType] = useState<PASSPORT_TYPE>(PASSPORT_TYPE.REGULAR_PASSPORT);
    const [focusedInputName, setFocusedInputName] = useState<INPUT | null>(null);
    const [isEdit, setIsEdit] = useState<boolean>(!!props.equippedUser.pdSentAt);

    const identifierRef = useOrangeInputRef();
    const firstNameRef = useOrangeInputRef();
    const lastNameRef = useOrangeInputRef();
    const middleNameRef = useOrangeInputRef();
    const birthdayRef = useRef<IOrangeDateRef>(null);

    const passportNameRef = useOrangeInputRef();
    const passportSeriesRef = useOrangeInputRef();
    const passportNumberRef = useOrangeInputRef();
    const passportAuthorityRef = useOrangeInputRef();
    const passportIssueDateRef = useRef<IOrangeDateRef>(null);
    const passportDepartmentCodeRef = useOrangeInputRef();

    const defaultItem = getDefaultDataItem(props.equippedClient, props.equippedUser);

    const onClear = () => {
        firstNameRef.current?.clearValue();
        firstNameRef.current?.setError(null);
        lastNameRef.current?.clearValue();
        lastNameRef.current?.setError(null);
        middleNameRef.current?.clearValue();
        middleNameRef.current?.setError(null);
        birthdayRef.current?.clearValue();

        setPassportType(PASSPORT_TYPE.REGULAR_PASSPORT);
        passportSeriesRef.current?.clearValue();
        passportSeriesRef.current?.setError(null);
        passportNumberRef.current?.clearValue();
        passportNumberRef.current?.setError(null);
        passportAuthorityRef.current?.clearValue();
        passportAuthorityRef.current?.setError(null);
        passportIssueDateRef.current?.clearValue();
        passportDepartmentCodeRef.current?.clearValue();
        passportDepartmentCodeRef.current?.setError(null);
    };
    const onFocus = (input: INPUT) => {
        setFocusedInputName(input);
    };
    const onBlur = () => {
        setFocusedInputName(null);
    };
    const onCancelEditing = () => {
        onClear();
        setIsEdit(true);
    };

    useImperativeHandle(ref, () => {
        return {
            getUser() {
                return props.equippedUser;
            },
            getIsEdit() {
                return isEdit;
            },
            getValue() {
                return {
                    ...defaultItem,
                    identifier: props.equippedUser.identifier,
                    person: {
                        ...defaultItem.person,
                        firstName: firstNameRef.current?.getValue() ?? defaultItem.person.firstName,
                        lastName: lastNameRef.current?.getValue() ?? defaultItem.person.lastName,
                        middleName: middleNameRef.current?.getValue() ?? defaultItem.person.middleName,
                        birthday: birthdayRef.current?.getValue() ?? defaultItem.person.birthday,
                    },
                    passport: {
                        ...defaultItem.passport,
                        name: 'Паспорт',
                        type: passportType,
                        series: passportSeriesRef.current?.getValue() ?? defaultItem.passport.series,
                        number: passportNumberRef.current?.getValue() ?? defaultItem.passport.number,
                        issueDate: passportIssueDateRef.current?.getValue() ?? defaultItem.passport.issueDate,
                        authority: passportAuthorityRef.current?.getValue() ?? defaultItem.passport.authority,
                        departmentCode: passportDepartmentCodeRef.current?.getValue() ?? defaultItem.passport.departmentCode,
                    },
                };
            },
            checkIsAllProvided() {
                return (
                    [
                        !!identifierRef.current?.getValue(),
                        !!firstNameRef.current?.getValue(),
                        !!lastNameRef.current?.getValue(),
                        !!middleNameRef.current?.getValue(),
                        !!birthdayRef.current?.getValue(),
                        !!passportNameRef.current?.getValue(),
                        !!passportSeriesRef.current?.getValue(),
                        !!passportNumberRef.current?.getValue(),
                        !!passportAuthorityRef.current?.getValue(),
                        !!passportIssueDateRef.current?.getValue(),
                        !!passportDepartmentCodeRef.current?.getValue(),
                    ].includes(false)
                );
            },
            setValue(value) {
                identifierRef.current?.setValue(value.identifier);
                firstNameRef.current?.setValue(value.person.firstName);
                lastNameRef.current?.setValue(value.person.lastName);
                middleNameRef.current?.setValue(value.person.middleName);
                birthdayRef.current?.setValue(value.person.birthday as Date);

                passportNameRef.current?.setValue('Паспорт');
                setPassportType(value.passport.type);
                passportSeriesRef.current?.setValue(value.passport.series);
                passportNumberRef.current?.setValue(value.passport.number);
                passportAuthorityRef.current?.setValue(value.passport.authority);
                passportIssueDateRef.current?.setValue(value.passport.issueDate as Date);
                passportDepartmentCodeRef.current?.setValue(value.passport.departmentCode);
            },
            async validate() {
                setTimeout(() => {
                    firstNameRef.current?.setError(null);
                    lastNameRef.current?.setError(null);
                    middleNameRef.current?.setError(null);
                    birthdayRef.current?.setError(null);

                    passportSeriesRef.current?.setError(null);
                    passportNumberRef.current?.setError(null);
                    passportAuthorityRef.current?.setError(null);
                    passportIssueDateRef.current?.setError(null);
                    passportDepartmentCodeRef.current?.setError(null);
                }, 5000);

                return !(await Promise.all([
                    !!props.equippedUser.identifier,
                    firstNameRef.current?.validate(),
                    lastNameRef.current?.validate(),
                    middleNameRef.current?.validate(),
                    birthdayRef.current?.validate(),

                    passportSeriesRef.current?.validate(),
                    passportNumberRef.current?.validate(),
                    passportAuthorityRef.current?.validate(),
                    passportIssueDateRef.current?.validate(),
                    passportDepartmentCodeRef.current?.validate(),
                ])).includes(false);
            },
            isPartlyFilled() {
                const checkArray = [
                    !!firstNameRef.current?.getValue(),
                    !!lastNameRef.current?.getValue(),
                    !!middleNameRef.current?.getValue(),
                    !!birthdayRef.current?.getValue(),

                    !!passportSeriesRef.current?.getValue(),
                    !!passportNumberRef.current?.getValue(),
                    !!passportIssueDateRef.current?.getValue(),
                    !!passportAuthorityRef.current?.getValue(),
                    !!passportDepartmentCodeRef.current?.getValue(),
                ];

                return checkArray.includes(true) && checkArray.includes(false);
            },
            isFullFilled() {
                const checkArray = [
                    !!firstNameRef.current?.getValue(),
                    !!lastNameRef.current?.getValue(),
                    !!middleNameRef.current?.getValue(),
                    !!birthdayRef.current?.getValue(),

                    !!passportSeriesRef.current?.getValue(),
                    !!passportNumberRef.current?.getValue(),
                    !!passportIssueDateRef.current?.getValue(),
                    !!passportAuthorityRef.current?.getValue(),
                    !!passportDepartmentCodeRef.current?.getValue(),
                ];

                return !checkArray.includes(false);
            },
        };
    });

    return (
        <div className={classNames('list-group-item border border-3 p-2 mb-2', {
            ['border-success']: props.equippedUser.pdSentAt,
            ['border-danger']: !props.equippedUser.pdSentAt,
            ['border-warning']: !!props.equippedUser.pdSentAt && !isEdit,
        })}
        >
            {
                !props.equippedUser.pdSentAt &&
                <div className={'text-end fw-bold text-primary mb-1 me-2'}>Все поля обязательны для заполнения</div>
            }
            <div className={'row'}>
                <div className={'col-6 fs-4 fw-bold'}>
                    {`Идентификатор: ${props.equippedUser.identifierType === IDENTIFIER_TYPE.PHONE &&
                    props.equippedUser.identifier.split('')[0] !== '+' ? '+' + props.equippedUser.identifier : props.equippedUser.identifier}`}
                </div>
                <div className={'col-6 d-flex justify-content-end'}>
                    <div className={classNames('text-end me-2', {
                        ['text-success']: props.equippedUser.pdSentAt,
                        ['text-primary fw-bold']: !props.equippedUser.pdSentAt,
                    })}
                    >
                        {
                            props.equippedUser.pdSentAt ? isEdit ?
                                `Данные предоставлены: ${formatDate(props.equippedUser.pdSentAt)}` :
                                'Редактируется' :
                                'Данные не предоставлялись'
                        }
                    </div>
                    <div className={classNames(styles.indicator)}>
                        <MaterialTooltip
                            text={props.equippedUser.pdSentAt ? 'Все хорошо!' : 'Заполните, пожалуйста, а то отключим =('}
                            placement={'top'}
                        >
                            <img src={props.equippedUser.pdSentAt ? successIcon : warningIcon} alt={''}/>
                        </MaterialTooltip>
                    </div>
                </div>
                <h4 className={'text-center mt-4 mb-1'}>Данные пользователя оконечного оборудования</h4>
                <div className={'row mt-2'}>
                    <div className={'col-2'}>
                        <OrangeSelect
                            value={passportType}
                            isDisabled={isEdit}
                            values={[PASSPORT_TYPE.REGULAR_PASSPORT]}
                            formatOption={formatPassportType}
                            onChange={(value) => setPassportType(value as PASSPORT_TYPE)}
                        />
                    </div>
                    <div className={'col-2'}>
                        <OrangeInput
                            ref={lastNameRef}
                            className={'form-control-sm'}
                            placeholder={'Фамилия'}
                            initValue={defaultItem.person.lastName}
                            isDisabled={isEdit}
                            symbolRegexp={NAME_SYMBOL_REGEX}
                            maxLength={128}
                            isRequired={true}
                            capitalize={true}
                            onFocus={() => onFocus(INPUT.LAST_NAME)}
                            onBlur={onBlur}
                        />
                        {
                            focusedInputName === INPUT.LAST_NAME &&
                            <div className={styles.tooltip}>
                                Фамилия
                            </div>
                        }
                    </div>
                    <div className={'col-2'}>
                        <OrangeInput
                            ref={firstNameRef}
                            className={'form-control-sm'}
                            placeholder={'Имя'}
                            initValue={defaultItem.person.firstName}
                            isDisabled={isEdit}
                            symbolRegexp={NAME_SYMBOL_REGEX}
                            maxLength={128}
                            isRequired={true}
                            capitalize={true}
                            onFocus={() => onFocus(INPUT.FIRST_NAME)}
                            onBlur={onBlur}
                        />
                        {
                            focusedInputName === INPUT.FIRST_NAME &&
                            <div className={styles.tooltip}>
                                Имя
                            </div>
                        }
                    </div>
                    <div className={'col-2'}>
                        <OrangeInput
                            ref={middleNameRef}
                            className={'form-control-sm'}
                            placeholder={'Отчество'}
                            initValue={defaultItem.person.middleName}
                            isDisabled={isEdit}
                            symbolRegexp={NAME_SYMBOL_REGEX}
                            maxLength={128}
                            isRequired={passportType === PASSPORT_TYPE.REGULAR_PASSPORT}
                            capitalize={true}
                            onFocus={() => onFocus(INPUT.MIDDLE_NAME)}
                            onBlur={onBlur}
                        />
                        {
                            focusedInputName === INPUT.MIDDLE_NAME &&
                            <div className={styles.tooltip}>
                                Отчество
                            </div>
                        }
                    </div>
                    <div className={'col-2'}>
                        <OrangeDate
                            ref={birthdayRef}
                            className={'form-control-sm'}
                            placeholder={'Полная дата рождения'}
                            initValue={defaultItem.person.birthday as Date}
                            isDisabled={isEdit}
                            isRequired={true}
                            max={new Date()}
                            onFocus={() => onFocus(INPUT.BIRTHDAY)}
                            onBlur={onBlur}
                        />
                        {
                            focusedInputName === INPUT.BIRTHDAY &&
                            <div className={styles.tooltipLeft}>
                                Полная дата рождения
                            </div>
                        }
                    </div>
                </div>
                <div className={'row mt-2'}>
                    <div className={'col-2'}>
                        <OrangeInput
                            ref={passportSeriesRef}
                            className={'form-control-sm'}
                            placeholder={'Серия'}
                            initValue={defaultItem.passport.series}
                            isDisabled={isEdit}
                            mask={passportType === PASSPORT_TYPE.INTERNATIONAL_PASSPORT ? '9999999999999999' : '99 99'}
                            isRequired={true}
                            onFocus={() => onFocus(INPUT.PASSPORT_SERIES)}
                            onBlur={onBlur}
                        />
                        {
                            focusedInputName === INPUT.PASSPORT_SERIES &&
                            <div className={styles.tooltip}>
                                Серия
                            </div>
                        }
                    </div>
                    <div className={'col-2'}>
                        <OrangeInput
                            ref={passportNumberRef}
                            className={'form-control-sm'}
                            placeholder={'Номер'}
                            initValue={defaultItem.passport.number}
                            isDisabled={isEdit}
                            mask={passportType === PASSPORT_TYPE.INTERNATIONAL_PASSPORT ? '9999999999999999' : '999999'}
                            isRequired={true}
                            onFocus={() => onFocus(INPUT.PASSPORT_NUMBER)}
                            onBlur={onBlur}
                        />
                        {
                            focusedInputName === INPUT.PASSPORT_NUMBER &&
                            <div className={styles.tooltip}>
                                Номер
                            </div>
                        }
                    </div>
                    <div className={'col-2'}>
                        <OrangeDate
                            ref={passportIssueDateRef}
                            className={'form-control-sm'}
                            placeholder={'Дата выдачи'}
                            initValue={defaultItem.passport.issueDate as Date}
                            isDisabled={isEdit}
                            isRequired={true}
                            max={new Date()}
                            onFocus={() => onFocus(INPUT.PASSPORT_ISSUE_DATE)}
                            onBlur={onBlur}
                        />
                        {
                            focusedInputName === INPUT.PASSPORT_ISSUE_DATE &&
                            <div className={styles.tooltipLeft}>
                                Дата выдачи
                            </div>
                        }
                    </div>
                    <div className={'col-4'}>
                        <OrangeInput
                            ref={passportAuthorityRef}
                            className={'form-control-sm'}
                            placeholder={'Наименование органа, выдавшего документ'}
                            initValue={defaultItem.passport.authority}
                            isDisabled={isEdit}
                            isRequired={true}
                            onFocus={() => onFocus(INPUT.PASSPORT_AUTHORITY)}
                            onBlur={onBlur}
                            symbolRegexp={PASSPORT_AUTHORITY_REGEX}
                            maxLength={300}
                        />
                        {
                            focusedInputName === INPUT.PASSPORT_AUTHORITY &&
                            <div className={styles.tooltip}>
                                Наименование органа, выдавшего документ
                            </div>
                        }
                    </div>
                    <div className={'col-2'}>
                        <OrangeInput
                            ref={passportDepartmentCodeRef}
                            className={'form-control-sm'}
                            placeholder={'Код подразделения'}
                            initValue={defaultItem.passport.departmentCode}
                            isDisabled={isEdit}
                            mask={passportType === PASSPORT_TYPE.INTERNATIONAL_PASSPORT ? '9999999999999999' : '999-999'}
                            isRequired={true}
                            onFocus={() => onFocus(INPUT.PASSPORT_DEPARTMENT_CODE)}
                            onBlur={onBlur}
                        />
                        {
                            focusedInputName === INPUT.PASSPORT_DEPARTMENT_CODE &&
                            <div className={styles.tooltip}>
                                Код подразделения
                            </div>
                        }
                    </div>

                </div>
            </div>
            <div className={'row mt-4'}>
                <h4 className={'text-center'}>Данные об услуге</h4>
                {
                    props.equippedUser.serviceName &&
                    <div className={'row mb-2'}>
                        <div className={'col-12'}>
                            <span>
                                Наименование услуги:
                            </span>
                            <b>
                                {` ${props.equippedUser.serviceName}`}
                            </b>
                        </div>
                    </div>
                }
                <div className={'col-3 '}>
                    <span>
                            Номер бланка заказа:
                    </span>
                    <b>
                        {` ${props.equippedUser.orderBlankNumber || '-'}`}
                    </b>
                </div>
                <div className={'col-3'}>
                    <span>
                          Город:
                    </span>
                    <b>
                        {` ${props.equippedUser.city || '-'}`}
                    </b>
                </div>
                <div className={'col-6 d-flex flex-row'}>
                    <div>
                        Адрес предоставления услуги:
                    </div>
                    <div className={'ms-1'}>
                        <b>
                            {`${props.equippedUser.address || '-'}`}
                        </b>
                    </div>
                </div>
            </div>
            <div className={'row mt-2'}>
                <div className={'col-3'}>
                    <span>
                          Контракт:
                    </span>
                    <b>
                        {` ${props.equippedUser.contractNumber || '-'}`}
                    </b>
                </div>
                <div className={'col-3 d-flex flex-row'}>
                    <div className={'m-0 p-0 d-flex'}>
                        Дата начала предоставления услуги (по бланку заказа):
                    </div>
                    <div className={'d-flex ms-auto'}>
                        <b>
                            {` ${props.equippedUser.serviceStartedAt ? formatDate(props.equippedUser.serviceStartedAt) : '-'}`}
                        </b>
                    </div>
                </div>
            </div>
            <div className={'row align-items-center mt-2'}>
                <div className={'col-12 d-flex flex-row justify-content-end'}>
                    {
                        isEdit ?
                            <button
                                className={'btn btn-sm btn-secondary me-2'}
                                onClick={() => setIsEdit(false)}
                            >
                                Указать актуальные данные
                            </button> :
                            <>
                                <button
                                    className={'btn btn-sm btn-secondary me-2'}
                                    onClick={props.onDuplicateAll}
                                >
                                    Скопировать на всё
                                </button>
                                {
                                    !props.isLast &&
                                    <button
                                        className={'btn btn-sm btn-secondary me-2'}
                                        onClick={props.onDuplicate}
                                    >
                                        Скопировать вниз
                                    </button>
                                }
                                <button
                                    className={'btn btn-sm btn-secondary'}
                                    onClick={onClear}
                                >
                                    Очистить
                                </button>
                                {
                                    !!props.equippedUser.pdSentAt && !isEdit &&
                                    <button
                                        className={'btn btn-sm btn-secondary ms-2'}
                                        onClick={onCancelEditing}
                                    >
                                        Отменить редактирование
                                    </button>
                                }
                            </>
                    }
                </div>
            </div>
        </div>
    );
});

export default PdIdentifiersItem;
