import {
    ICheckUniqueByAdminQuery,
    IGetByAdminQuery,
    IGetByUserQuery,
    IPatchByAdminBody,
    IPatchByAuthBody,
    IPostByAdminBody,
} from './types/requests';
import {
    ICheckUniqueByAdmin,
    IGetByAdmin,
    IGetByUser,
    IGetOneByAdmin,
    IGetOneByAuth,
    IPatchByAdmin,
    IPatchByAuth,
    IPostByAdmin,
    IRemoveByAdmin,
    IResurrectByAdmin,
} from './types/responses';

import config from 'config';

import Fetch from 'tools/Fetch';

import {onLogout} from 'instances/auth/functions';

import {INSTANCE_PATH} from './constants';

export async function checkUniqueByAdmin(query: ICheckUniqueByAdminQuery): Promise<ICheckUniqueByAdmin> {
    return new Fetch<ICheckUniqueByAdmin>({
        url: `${config.URL}${INSTANCE_PATH}/admin/unique`,
        method: 'GET',
        query,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([403, 500], (body) => {
            return body;
        })
        .exec();
}

export async function getByAdmin(query: IGetByAdminQuery): Promise<IGetByAdmin> {
    return new Fetch<IGetByAdmin>({
        url: `${config.URL}${INSTANCE_PATH}/admin`,
        method: 'GET',
        query,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([403, 500], (body) => {
            return body;
        })
        .exec();
}

export async function getOneByAdmin(id: string): Promise<IGetOneByAdmin> {
    return new Fetch<IGetOneByAdmin>({
        url: `${config.URL}${INSTANCE_PATH}/admin/${id}`,
        method: 'GET',
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            return body;
        })
        .exec();
}

export async function getOneByAuth(): Promise<IGetOneByAuth> {
    return new Fetch<IGetOneByAuth>({
        url: `${config.URL}${INSTANCE_PATH}/auth`,
        method: 'GET',
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([500], (body) => {
            return body;
        })
        .exec();
}

export async function getByUser(query: IGetByUserQuery): Promise<IGetByUser> {
    return new Fetch<IGetOneByAuth>({
        url: `${config.URL}${INSTANCE_PATH}/user`,
        method: 'GET',
        query,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([500], (body) => {
            return body;
        })
        .exec();
}

export async function postByAdmin(body: IPostByAdminBody): Promise<IPostByAdmin> {
    return new Fetch<IPostByAdmin>({
        url: `${config.URL}${INSTANCE_PATH}/admin`,
        method: 'POST',
        body,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([400, 403, 500], (body) => {
            return body;
        })
        .exec();
}

export async function patchByAdmin(body: IPatchByAdminBody): Promise<IPatchByAdmin> {
    return new Fetch<IPatchByAdmin>({
        url: `${config.URL}${INSTANCE_PATH}/admin`,
        method: 'PATCH',
        body,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            return body;
        })
        .exec();
}

export async function patchByAuth(body: IPatchByAuthBody): Promise<IPatchByAuth> {
    return new Fetch<IPatchByAuth>({
        url: `${config.URL}${INSTANCE_PATH}/auth`,
        method: 'PATCH',
        body,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([400, 500], (body) => {
            return body;
        })
        .exec();
}

export async function removeByAdmin(id: string): Promise<IRemoveByAdmin> {
    return new Fetch<IRemoveByAdmin>({
        url: `${config.URL}${INSTANCE_PATH}/admin/${id}`,
        method: 'DELETE',
    })
        .onEmptyBody([204])
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            return body;
        })
        .exec();
}

export async function resurrectByAdmin(id: string): Promise<IResurrectByAdmin> {
    return new Fetch<IRemoveByAdmin>({
        url: `${config.URL}${INSTANCE_PATH}/admin/resurrect/${id}`,
        method: 'POST',
    })
        .onEmptyBody([204])
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            return body;
        })
        .exec();
}
